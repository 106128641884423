import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import './index.scss'

const chapter1 = [
  'Appreciate the click!', 
  'Nice', 
  'Thank you', 
  'You seem to keep going', 
  'Okay.', 
  'You are still at it.', 
  'This is why there is no hover effect', 
  'Stop please', 
  'Why do you keep clicking', 
  'Seriously now', 
  '...', 
  '...', 
  '...',
  'No?',
  'Are you sure?',
  'I warned you.',
  'What a change, right?',
  "Let's start with a blank slate.",
]

const chapter2 = [
  'Way better! ✨',
  'You did something that not many people do. You clicked.',
  'It was not quite obvious that you can do it. But you tried anyway. And you keep going.', 
  "You are curious.",
  "Be very proud of that and don't let it go to waste.",
  "If we let it go to waste, they will win.",
  "Who, you ask? Soulless, boring software. Littered with ads, notifications, alerts, and pop-ups. Powered by A/B tests, growth hacks and layers of convoluted decisions that lost track of what was being decided in the first place.",
  "We are engineers, designers, marketers, or founders, but it's curiosity that connects us and lies at the heart of everything we do. We shape the modern experiences, we conjure feelings with mere pixels and we build tools that enhance the human intellect.",
  "But where to start?",
  <>There are some resources that can help. Resources about <a href='http://worrydream.com/MagicInk/'>software interfaces</a>, about the <a href='https://dougengelbart.org/content/view/276/'>personal computer</a>, on <a href='https://www.edwardtufte.com/tufte/books_vdqi'>displaying information</a> or about the <a href='https://www.youtube.com/watch?v=8pTEmbeENF4'>future of programming</a>.</>,
  "Or simply start with whatever comes next. Just like it started here with one innocent click.",
  "Good luck. I will be cheering from the sidelines.",
  "If you need help, you know how to find me.",
  "Bye :)",
]

const IndexPage = () => {
  const size = useWindowSize();
  const [clicks, setClicks] = React.useState(0)
  const [wasMagic, setWasMagic] = React.useState(false)
  const isMagic = clicks > 15

  console.log(size)

  return (
    <Layout>
      <SEO title="Home" />
      <div className='container'>
          {!isMagic &&  
            <div className='landing-section'>
              <div className='landing-blurb'>
                <h1>Hey, I'm Filip Stollar. I design & code next-generation data science interfaces at <a target="_blank" rel="noopener noreferrer" href='https://www.deepnote.com/'>Deepnote</a></h1>
                <h1>I also work on some side projects, namely <a target="_blank" rel="noopener noreferrer" href='https://www.notionery.com/'>Notionery</a> and <a target="_blank" rel="noopener noreferrer" href='https://www.templatery.co'>Templatery</a></h1>
                <div className='links'>
                  <a href="https://www.linkedin.com/in/filipstollar/">LinkedIn</a>
                  <a href="http://twitter.com/avesunaden">Twitter</a>
                  <a href="https://github.com/SuNaden">GitHub</a>
                  <a href="mailto:stollarf@gmail.com">Email</a>
                </div>
              </div>
              <div className='landing-image'>
                <StaticImage onClick={() => {
                  if (size.width <= 960) {
                    setClicks(0)
                    setWasMagic(false)
                    return
                  }
                  if (wasMagic) {
                    setClicks(1)
                    return
                  }
                  setClicks(clicks + 1)
                  }} src='../images/me.jpg' height={480} title='It me' alt='Filip Stollar picture' />
              </div>
              <div className='whatisthis'> 
              {wasMagic && clicks >= 1 ? <div>Haha, wasn't once enough?</div> : [...Array(clicks)].map((click, index) => <div key={click}>{chapter1[index]}</div>) }
              </div>
            </div>
          }
          {
            isMagic && 
            <div className='magic-section'>
              <div className='magic-button'>
                <button onClick={() => {
                  if (clicks >= chapter1.length + chapter2.length) {
                    setWasMagic(true)
                    setClicks(0)
                    return
                  }
                  setClicks(clicks + 1)}
                } className='card' >Button</button>
              </div>
              <div className='magic-story'>
                {clicks <= chapter1.length && <div className='magic-chapter-1'>
                  {[...Array(clicks)].map((click, index) => <div key={click + 'chapter1'} className={index > 15 && 'bold'}>{chapter1[index]}</div>)}
                </div>}
                {clicks > chapter1.length && <div className='magic-chapter-2'>
                  {[...Array(clicks - chapter1.length)].map((click, index) => <div key={click + 'chapter2'}>{chapter2[index]}</div>)}
                </div>}
              </div>
            </div>
          }
      </div>
    </Layout>
  )
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });
  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default IndexPage
